import React, { Component } from "react";
import Chart from "./Chart";
import { subDays } from "date-fns";
import { subWeeks, subMonths, subYears, differenceInDays } from "date-fns/esm";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NewDashboardReport } from "./NewDashboardReport/NewDashboardReport";

export interface log {
  timestamp: Date;
  doctorID: number;
  practiceID: number;
  event: string;
}

interface MappedLogs {
  [key: string]: log[];
}

interface DashboardProps {
  loginHash: string;
}

interface DashboardState {
  logs: log[];
  eventTypes: string[];
  logsByType: MappedLogs;
  startTimestamp: Date;
  endTimestamp: Date;
  period: string;
  interval: string;
}

interface ControlProps {
  onChangePeriod: any;
  onChangeInterval: any;
  onChangeStart: any;
  onChangeEnd: any;
}

const DashboardControl: React.FC<ControlProps & DashboardState> = (props) => {
  return (
    <div id="dashboard-control">
      <div className="control-group">
        <span>From the past:</span>
        <select onChange={props.onChangePeriod} value={props.period}>
          <option>1 day</option>
          <option>1 week</option>
          <option>2 weeks</option>
          <option>1 month</option>
          <option>6 months</option>
          <option>1 year</option>
        </select>
      </div>
      <div className="control-group">
        <span>Start:</span>
        <DatePicker
          selected={props.startTimestamp}
          onChange={props.onChangeStart}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className="control-group">
        <span>End:</span>
        <DatePicker
          selected={props.endTimestamp}
          onChange={props.onChangeEnd}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      <div className="control-group">
        <span>Group by:</span>
        <select value={props.interval} onChange={props.onChangeInterval}>
          <option>hour</option>
          <option>day</option>
          <option>week</option>
          <option>month</option>
          <option>year</option>
        </select>
      </div>
    </div>
  );
};

function calcStartTimestamp(endTimestamp: Date, period: string): Date {
  let startTimestamp = new Date(endTimestamp);
  startTimestamp.setHours(0, 0, 0, 0);

  switch (period) {
    case "1 day":
      break;
    case "1 week":
      startTimestamp = subDays(subWeeks(startTimestamp, 1), -1);
      break;
    case "2 weeks":
      startTimestamp = subDays(subWeeks(startTimestamp, 2), -1);
      break;
    case "1 month":
      startTimestamp = subDays(subMonths(startTimestamp, 1), -1);
      break;
    case "6 months":
      startTimestamp = subDays(subMonths(startTimestamp, 6), -1);
      break;
    case "1 year":
      startTimestamp = subDays(subYears(startTimestamp, 1), -1);
      break;
    default:
      startTimestamp = subDays(subMonths(startTimestamp, 1), -1);
  }

  return startTimestamp;
}

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);

    let period = "1 week";
    let endTimestamp = new Date();
    endTimestamp.setHours(23, 59, 59, 999);

    this.state = {
      logs: [],
      eventTypes: [],
      logsByType: {},
      startTimestamp: calcStartTimestamp(endTimestamp, period),
      endTimestamp: endTimestamp,
      period: period,
      interval: "day",
    };

    this.fetchData();
  }

  fetchData = () => {
    fetch("/api/fetch", {
      method: "post",
      headers: new Headers({
        Authorization: "Basic " + this.props.loginHash,
      }),
      body: JSON.stringify({
        start: this.state.startTimestamp,
        end: this.state.endTimestamp,
      }),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        if (json.ok) {
          let eventTypes: string[] = [];
          let logsByType: MappedLogs = {};

          for (let i = 0; i < json.logs.length; i++) {
            let event = json.logs[i].event;
            if (eventTypes.indexOf(event) === -1) {
              eventTypes.push(event);
              logsByType[event] = [];
            }
            logsByType[event].push(json.logs[i]);
          }

          this.setState({
            logs: json.logs,
            eventTypes: eventTypes,
            logsByType: logsByType,
          });
        }
      });
  };

  smartInterval = (start: Date, end: Date): string => {
    let diff = differenceInDays(end, start);
    if (diff < 7) {
      return "hour";
    } else if (diff <= 14) {
      return "day";
    } else if (diff <= 31) {
      return "week";
    } else if (diff <= 24 * 31) {
      return "month";
    } else {
      return "year";
    }
  };

  onChangePeriod = (e: any) => {
    let period = e.target.value;
    let startTimestamp = calcStartTimestamp(this.state.endTimestamp, period);
    let interval = this.smartInterval(startTimestamp, this.state.endTimestamp);
    this.setState(
      {
        interval: interval,
        period: period,
        startTimestamp: startTimestamp,
      },
      this.fetchData
    );
  };

  onChangeInterval = (e: any) => {
    this.setState(
      {
        interval: e.target.value,
      },
      this.fetchData
    );
  };

  onChangeStart = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    let interval = this.smartInterval(date, this.state.endTimestamp);
    this.setState({ startTimestamp: date, interval: interval }, this.fetchData);
  };
  onChangeEnd = (date: Date) => {
    date.setHours(23, 59, 59, 999);
    let interval = this.smartInterval(this.state.startTimestamp, date);
    this.setState({ endTimestamp: date, interval: interval }, this.fetchData);
  };

  render() {
    let charts = [];
    for (let i = 0; i < this.state.eventTypes.length; i++) {
      let eventType = this.state.eventTypes[i];
      charts.push(
        <Chart
          key={eventType}
          event={eventType}
          logs={this.state.logsByType[eventType]}
          startTimestamp={this.state.startTimestamp}
          endTimestamp={this.state.endTimestamp}
          interval={this.state.interval}
        />
      );
    }

    return (
      <div>
        <DashboardControl
          {...this.state}
          onChangePeriod={this.onChangePeriod}
          onChangeInterval={this.onChangeInterval}
          onChangeStart={this.onChangeStart}
          onChangeEnd={this.onChangeEnd}
        />
        <NewDashboardReport
          loginHash={this.props.loginHash}
          startTS={this.state.startTimestamp}
          endTS={this.state.endTimestamp}
        />
        <hr />
        <h2>Event Charts</h2>
        {charts}
      </div>
    );
  }
}

export default Dashboard;
