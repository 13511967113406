import React, { Component } from "react";

import "./Login.css";

interface LoginProps {
    onSubmit: any;
}

interface LoginState {
    loggingIn: boolean;
    password: string;
}

class Login extends Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            loggingIn: false,
            password: ""
        };
    }

    updatePassword = (e: any) => {
        this.setState({ password: e.target.value });
    };

    submitForm = (e: any) => {
        e.preventDefault();

        this.setState({ loggingIn: true });

        fetch("/api/login", {
            method: "POST",
            body: JSON.stringify({ password: this.state.password })
        })
            .then(resp => {
                return resp.json();
            })
            .then(json => {
                if (json.ok) {
                    this.props.onSubmit(json.hash);
                } else {
                    this.setState({ password: "", loggingIn: false });
                    alert(
                        "The password you entered is wrong. Please try again."
                    );
                }
            });
    };

    render() {
        if (this.state.loggingIn) {
            return <div id='login'>
                Logging you in, please wait...
            </div>
        } else {
            return (
                <form id="login" onSubmit={this.submitForm}>
                    Password:&nbsp;&nbsp;
                    <input
                        type="password"
                        value={this.state.password}
                        onChange={this.updatePassword}
                    />
                    <input type="submit" value="Login" />
                </form>
            );
        }
    }
}

export default Login;
