import React, { Component, Props } from "react";
import "./App.css";
import Dashboard from "./Dashboard";
import Login from "./Login";

interface ComponentState {
  loginHash: string;
}

class App extends Component<{}, ComponentState> {
  constructor(props: Props<{}>) {
    super(props);
    this.state = {
      loginHash: "",
    };
  }

  updateLogin = (hash: string) => {
    this.setState({ loginHash: hash });
  };

  render() {
    if (this.state.loginHash !== "") {
      return <Dashboard loginHash={this.state.loginHash} />;
    } else {
      return <Login onSubmit={this.updateLogin} />;
    }
  }
}

export default App;
