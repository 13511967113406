import React, { Fragment, useEffect, useState } from "react";
import "./NewDashboardReport.css";

interface DoctorRole {
  id: number;
  name: string;
}

export const NewDashboardReport = (props: {
  startTS: Date;
  endTS: Date;
  loginHash: string;
}) => {
  const [numDoctors, setNumDoctors] = useState(0);
  const [numPractices, setNumPractices] = useState(0);
  const [roles, setRoles] = useState<DoctorRole[]>([]);
  const [acceptedCounts, setAcceptedCounts] = useState<{ [k: number]: number }>(
    {}
  );
  const [completedCounts, setCompletedCounts] = useState<{
    [k: number]: number;
  }>({});
  const [acceptedHrs, setAcceptedHrs] = useState(0);
  const [completedHrs, setCompletedHrs] = useState(0);

  const fetchData = async () => {
    const resp = await fetch("/api/fetchReport", {
      method: "post",
      headers: new Headers({
        Authorization: "Basic " + props.loginHash,
      }),
      body: JSON.stringify({
        start: props.startTS,
        end: props.endTS,
      }),
    });

    const json = await resp.json();
    setRoles(json.doctor_roles);
    setNumDoctors(json.num_doctors);
    setNumPractices(json.num_practices);
    setAcceptedCounts(json.accepted_counts);
    setCompletedCounts(json.completed_counts);
    setAcceptedHrs(json.accepted_hrs / 60);
    setCompletedHrs(json.completed_hrs / 60);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startTS, props.endTS]);

  return (
    <div id="dashboard-report">
      <h2>Overall View</h2>
      <div className="dashboard-report-section">
        <Box value={numDoctors} text="No. of GP profiles" />
        <Box value={numPractices} text="No. of practice profiles" />
        <Box value={acceptedHrs.toFixed(1)} text="No. of accepted job hours" />
        <Box
          value={completedHrs.toFixed(1)}
          text="No. of completed job hours"
        />
      </div>
      <h2>Numbers by Locum Roles</h2>
      <div className="dashboard-report-section">
        {roles.map((role) => {
          const accepted = acceptedCounts[role.id];
          const completed = completedCounts[role.id];

          return (
            <Fragment key={role.id}>
              <Box
                value={accepted > 0 ? accepted : 0}
                text={
                  "No. of " +
                  role.name.toLowerCase() +
                  " GPs that have accepted at least one job offer"
                }
              />
              <Box
                value={completed > 0 ? completed : 0}
                text={
                  "No. of " +
                  role.name.toLowerCase() +
                  " GPs that have completed at least one job"
                }
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const Box = (props: { value: number | string; text: string }) => (
  <div className="box">
    <div className="box-value">{props.value}</div>
    <div className="box-text">{props.text}</div>
  </div>
);
