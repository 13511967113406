import React, { Component } from "react";
import { log } from "./Dashboard";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "./Chart.css";

Chart.register(...registerables);

var moment = require("moment");
moment().format();
var _ = require("lodash");

interface ChartProps {
  logs: log[];
  event: string;

  startTimestamp: Date;
  endTimestamp: Date;
  interval: string;
}

const dateFormat = "D MMM YY";

class ChartComponent extends Component<ChartProps> {
  parseData = () => {
    let listCounts: number[] = [];
    let listDocCounts: number[] = [];
    let listPracCounts: number[] = [];
    let labels = [];

    let groupbyFn = (log: log) => {
      return moment(log.timestamp)
        .startOf(this.props.interval)
        .format(dateFormat);
    };

    // let start = moment(this.props.startTimestamp).startOf('week');
    // let end = moment(this.props.endTimestamp).endOf('week');

    let grouped = _.groupBy(this.props.logs, groupbyFn);

    let currTime = moment(this.props.startTimestamp).startOf(
      this.props.interval
    );
    let endTime = moment(this.props.endTimestamp).endOf(this.props.interval);

    while (currTime <= endTime) {
      let timestampStr = currTime.format(dateFormat);
      let count = 0,
        docSet = new Set(),
        pracSet = new Set();
      if (timestampStr in grouped) {
        let intervalData = grouped[timestampStr];
        count = intervalData.length;

        for (let i = 0; i < intervalData.length; i++) {
          if (intervalData[i].doctorID > 0) {
            docSet.add(intervalData[i].doctorID);
          }
          if (intervalData[i].practiceID > 0) {
            pracSet.add(intervalData[i].practiceID);
          }
        }
      }

      listCounts.push(count);
      listDocCounts.push(docSet.size);
      listPracCounts.push(pracSet.size);

      labels.push(timestampStr);
      currTime.add(1, this.props.interval);
    }

    let dataset = {
      label: "No. of " + this.props.event,
      borderColor: "rgba(75,192,192,1)",
      fill: false,
      data: listCounts,
    };

    let docDataset = {
      label: "No. of doctors",
      borderColor: "rgba(175,92,192,1)",
      fill: false,
      data: listDocCounts,
    };

    let pracDataset = {
      label: "No. of practices",
      borderColor: "rgba(195,92,92,1)",
      fill: false,
      data: listPracCounts,
    };

    let datasets = [dataset];
    if (_.sum(listDocCounts)) {
      datasets.push(docDataset);
    }
    if (_.sum(listPracCounts)) {
      datasets.push(pracDataset);
    }

    return { labels: labels, datasets: datasets };
  };

  render() {
    let count = this.props.logs.length;
    let doctorsSet = new Set(),
      practiceSet = new Set();
    for (let i = 0; i < count; i++) {
      let log = this.props.logs[i];
      if (log.doctorID > 0) {
        doctorsSet.add(log.doctorID);
      }
      if (log.practiceID > 0) {
        practiceSet.add(log.practiceID);
      }
    }

    let data = this.parseData();

    return (
      <div className="chart">
        <h3>{this.props.event}</h3>
        <div className="chart-subtitle">
          {count} occurrences ({doctorsSet.size} doctors, {practiceSet.size}{" "}
          practices)
        </div>
        <Line data={data} height={200} options={{ animation: false }} />
      </div>
    );
  }
}

export default ChartComponent;
